import React from 'react';
import {ReactComponent as OpenInNew} from 'assets/images/icons/open-in-new.svg';
import {CompleteFeatureButton, CompleteFeatureListContent, CompleteFeatureListWrapper, IconWrapper} from "./styles";

const CompleteFeatureList: React.FC<{ isLegacyPlanType: boolean }> = ({isLegacyPlanType}) => {

        const href = `${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing${
            isLegacyPlanType ? '?utm_source=spaces_webapp' : '?spaces=true&utm_source=spaces_webapp'
        }`

        return (
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={href}
                style={{textDecoration: 'none'}}
            >
                <CompleteFeatureListWrapper>
                    <CompleteFeatureListContent>
                        <CompleteFeatureButton>See complete feature list</CompleteFeatureButton>
                        <IconWrapper>
                            <OpenInNew/>
                        </IconWrapper>
                    </CompleteFeatureListContent>
                </CompleteFeatureListWrapper>
            </a>
        );
    }
;
export default CompleteFeatureList;
