import { useField } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import countries from './countries.json';
import { LogicProps, Option } from './types';

const useLogic = ({ name, defaultValue, onChange }: LogicProps) => {
  const [
    { onBlur },
    { error, touched },
    { setValue, setTouched },
  ] = useField<string>(name);

  const hasError = !!error && touched;

  const options: Option[] = useMemo(() => {
    return countries.map(({ country, abbreviation }) => ({
      label: country,
      value: abbreviation,
    }));
  }, []);

  const defaultOption = useMemo(() => {
    return options.find((option) => option.value === defaultValue);
  }, [defaultValue, options]);

  const [internalValue, setInternalValue] = useState<Option | null>(defaultOption ?? null);

  useEffect(() => {
    if (onChange && defaultOption && !internalValue) {
      // we dont set the value for formik because we assume that
      // default value is already in formik's initial values
      onChange(defaultOption.value);
      setInternalValue(defaultOption);
    }
  }, [defaultOption, internalValue, onChange]);

  const handleChange = useCallback(
    (option: Option) => {
      setValue(option.value);
      setInternalValue(option);
      if (onChange) {
        onChange(option.value);
      }
    },
    [setValue, onChange],
  );

  const handleBlur = useCallback(
    (e: any) => {
      if (onBlur) {
        // formik doesn't set this field as touched
        // because the event target is not the
        // the same as the input with the name attribute
        setTouched(true);
        onBlur(e);
      }
    },
    [onBlur, setTouched],
  );

  return {
    error,
    handleChange,
    hasError,
    options,
    value: internalValue,
    onBlur: handleBlur,
  };
};

export default useLogic;
