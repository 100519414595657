import React from 'react';
import Plan from './Plan';
import LegacyPlanExplanation from './LegacyPlanExplanation';
import { useConnect, useFuturePrice } from './connect';
import { getPlanLabel } from '../../../../../utils/checkout';
import { LoadingSpinner } from './styles';
import { ConfirmCancelCycleChange } from './ConfirmCancelCycleChange';
import CustomPlan from './CustomPlan';
import {
  isOfflineSubscription,
  isSupportGivenSubscription,
} from '../../../../../utils/subscriptions';
import FreePlanCard from "../FreePlanCard";

export type CurrentPlanProps = ReturnType<typeof useConnect>  & {
  accountType: string;
};

const CurrentPlan = ({
  isLegacy,
  isPayPal,
  setConfirmCancelCycleChange,
  onConfirmCycleChangeCancel,
  isLoading,
  countMembers,
  manageSubscriptionData,
  urlPrefix,
  newCycle,
  confirmCancelCycleChange,
  isCanceling,
  subscription,
  pageRestrictions,
  featureRestrictions,
  legacyPlanType,
  accountType,
}: CurrentPlanProps) => {
  const { futurePrice } = useFuturePrice(subscription);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (subscription == null || subscription.workspacePlan === 'FREE') {
    return <FreePlanCard accountType={accountType}/>;
  }

  const isCustomPlan =
    isOfflineSubscription(subscription) ||
    isSupportGivenSubscription(subscription);

  return (
    <>
      {confirmCancelCycleChange && (
        <ConfirmCancelCycleChange
          onCancel={() => setConfirmCancelCycleChange(false)}
          onConfirm={onConfirmCycleChangeCancel}
          cycleChangeDate={new Date(subscription.endTimestamp)}
          isLoading={isCanceling}
        />
      )}
      {subscription.workspacePlan === 'LEGACY' && <LegacyPlanExplanation />}

      {isCustomPlan ? (
        <CustomPlan />
      ) : (
        <Plan
          onCancelCycleChange={() => setConfirmCancelCycleChange(true)}
          newCycle={newCycle}
          workspaceUrlPrefix={urlPrefix}
          name={getPlanLabel(subscription.workspacePlan, legacyPlanType)}
          paidUsers={countMembers.paid}
          freeUsers={countMembers.free}
          price={subscription.amount}
          newPrice={futurePrice}
          currency={subscription.currency}
          billingCycle={subscription.billingInterval}
          endDate={new Date(subscription.endTimestamp)}
          tax={subscription.taxRate || 0}
          isRenewal={subscription.autoRenew}
          isLegacy={isLegacy}
          isPayPal={isPayPal}
          manageSubscriptionLink={
            manageSubscriptionData?.stripeCustomerPortalUrl
          }
          pageRestrictions={pageRestrictions}
          featureRestrictions={featureRestrictions}
        />
      )}
    </>
  );
};

export default CurrentPlan;
