import React from 'react';
import PurchasePlanCard from './PurchasePlanCard';
import {
  StyledContainer,
  SectionLoading,
} from './styles';
import { getBillingPlan } from './data/billingPlans';
import { useConnect } from './connect';

export type PurchasePlansProps = ReturnType<typeof useConnect>;
const PurchasePlans = ({
  plan,
  navigate,
  isLoadingSubscription,
  prices,
  isGmail,
  restrictions,
  interval,
  legacyPlanType,
}: PurchasePlansProps) => {
  if (isLoadingSubscription) {
    return null;
  }

  return (
    <>
      {prices ? (
        <StyledContainer $onlyOne={restrictions.isPremiumPlanRestricted}>
          <PurchasePlanCard
            isLegacy={!!legacyPlanType}
            isCurrentPlan={plan === 'PRO'}
            isDowngrade={plan === 'PREMIUM'}
            interval={interval}
            onClick={navigate}
            price={isGmail ? prices.gmail.pro : prices.workspace.pro}
            isGmailUser={isGmail}
            {...getBillingPlan('pro', restrictions)}
          />
          {!restrictions.isPremiumPlanRestricted && (
            <PurchasePlanCard
              isLegacy={!!legacyPlanType}
              isDowngrade={false}
              isCurrentPlan={plan === 'PREMIUM'}
              interval={interval}
              onClick={navigate}
              price={prices.workspace.premium}
              isGmailUser={isGmail}
              {...getBillingPlan('premium', restrictions)}
            />
          )}
        </StyledContainer>
      ) : (
        <SectionLoading />
      )}
    </>
  );
};

export default PurchasePlans;
