import React from 'react';
import {
    FreePlanCardBadgeContainer, FreePlanCardContainer,
    FreePlanCardContent, FreePlanCardDetailBody, FreePlanCardBadgeText, FreePlanCardHeader,
    FreePlanCardLeftContainer,
    FreePlanCardRightContainer, FreePlanFeatureItem, BoldFreePlanFeatureItem
} from "./styles";

interface FreePlanCardProps {
    accountType: string
}

const FreePlanCard = ({accountType}: FreePlanCardProps) => {
    const shouldDisplayUserRestriction = accountType !== 'domain';
    return (
        <FreePlanCardContainer>
            <FreePlanCardContent>
                <FreePlanCardLeftContainer>
                    <FreePlanCardHeader>Free</FreePlanCardHeader>
                    <FreePlanCardDetailBody>
                        <FreePlanFeatureItem>
                            <BoldFreePlanFeatureItem>Up to 20</BoldFreePlanFeatureItem> recipients / user / day
                        </FreePlanFeatureItem>
                        {shouldDisplayUserRestriction && <FreePlanFeatureItem>1 user only</FreePlanFeatureItem>}
                        <FreePlanFeatureItem>
                            Real-time open, click, response, bounce and unsubscribe tracking
                        </FreePlanFeatureItem>
                    </FreePlanCardDetailBody>
                </FreePlanCardLeftContainer>
                <FreePlanCardRightContainer>
                    <FreePlanCardBadgeContainer>
                        <FreePlanCardBadgeText>Current plan</FreePlanCardBadgeText>
                    </FreePlanCardBadgeContainer>
                </FreePlanCardRightContainer>
            </FreePlanCardContent>
        </FreePlanCardContainer>
    );
};
export default FreePlanCard;