import styled from "styled-components";

export const FreePlanCardContainer = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.gray300};
    background: ${({theme}) => theme.colors.white};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const FreePlanCardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`;

export const FreePlanCardLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
`;

export const FreePlanCardRightContainer = styled.div`
    display: flex;
    width: 158px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
`;

export const FreePlanCardBadgeText = styled.div`
    color: ${({theme}) => theme.colors.gray800};
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.092px;
`;

export const FreePlanCardBadgeContainer = styled.div`
    display: flex;
    width: 117px;
    padding: 4px 16px;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    border: 1px solid ${({theme}) => theme.colors.gray800};
    background: ${({theme}) => theme.colors.white};
`;

export const FreePlanCardHeader = styled.b`
    align-self: stretch;
    color: ${({theme}) => theme.colors.gray800};
    font-family: ${({theme}) => theme.fonts.lexendDeca};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
`;

export const FreePlanCardDetailBody = styled.div`
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export const FreePlanFeatureItem = styled.div`
    color: ${({theme}) => theme.colors.gray800};
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: -0.21px;
`;

export const BoldFreePlanFeatureItem = styled.b`
    font-weight: 700;
`;