import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  ColumnedRow,
  CountrySelect,
  FieldsContainer,
  RadioInput,
} from './styles';
import { InputField } from '../../../../components/InputField';
import { MAX_CHARACTERS_CUT, MAX_CHARACTERS_FULL } from '../validation';
import { CheckoutFormValues } from '../../../../data/context/CheckoutContext/types';

export type CheckoutFormProps = {
  defaultCountry: string;
  handleCountryChange: (val?: string) => void;
  isEuropeanCountry: boolean;
  handleTypeOfBusinessChange: (val: boolean) => void;
  isBusinessPurchase: boolean;
  defaultVATNumber: string | undefined,
  defaultCompanyName: string | undefined,
  disabled?: boolean;
};

const CheckoutForm = ({
  defaultCountry,
  isEuropeanCountry,
  handleTypeOfBusinessChange,
  isBusinessPurchase,
  handleCountryChange,
  defaultVATNumber,
  defaultCompanyName,
  disabled,
}: CheckoutFormProps) => {
  const formik = useFormikContext<CheckoutFormValues>();

  const handleCountryChangeCaller = (val: string) => {
    handleTypeOfBusinessChange(false);
    handleCountryChange(val);
  };

  useEffect(() => {
    formik.setFieldValue('isBusinessPurchase', isBusinessPurchase);
    formik.setFieldValue('vatNumber', isBusinessPurchase ? defaultVATNumber : '');
    formik.setFieldValue('companyName', isBusinessPurchase ? defaultCompanyName : '');
    formik.setTouched({
      name: false,
      companyName: false,
      vatNumber: false,
    });
  }, [isBusinessPurchase]);

  return (
    <Form noValidate>
      <FieldsContainer>
        <CountrySelect
          name="country"
          placeholder="Select country"
          defaultValue={defaultCountry}
          onChange={handleCountryChangeCaller}
          isDisabled={disabled}
          label="Select country"
        />

        {isEuropeanCountry && (
          <ColumnedRow>
            <RadioInput
              label="Is this a business purchase?"
              name="isBusinessPurchase"
              onChange={handleTypeOfBusinessChange}
              disabled={disabled}
            />
            {isBusinessPurchase && (
              <InputField
                label="VAT ID"
                name="vatNumber"
                disabled={disabled}
                placeholder=""
              />
            )}
          </ColumnedRow>
        )}
        {isBusinessPurchase && (
          <InputField
            label="Company Name"
            name="companyName"
            maxLength={MAX_CHARACTERS_FULL}
            disabled={disabled}
            placeholder=""
          />
        )}
        <InputField
          label="Name"
          name="name"
          disabled={disabled}
          maxLength={
            isBusinessPurchase ? MAX_CHARACTERS_CUT : MAX_CHARACTERS_FULL
          }
          placeholder=""
        />
        <InputField
          label="Street address"
          name="street"
          disabled={disabled}
          placeholder=""
          maxLength={MAX_CHARACTERS_FULL}
        />
        <ColumnedRow>
          <InputField
            label="City"
            name="city"
            disabled={disabled}
            placeholder=""
            maxLength={MAX_CHARACTERS_FULL}
          />
          <InputField
            label="State / Province"
            name="state"
            disabled={disabled}
            isOptional
            placeholder=""
            maxLength={MAX_CHARACTERS_FULL}
          />
        </ColumnedRow>

        <InputField
          label="Postal code"
          name="code"
          disabled={disabled}
          isOptional
          placeholder=""
          maxLength={MAX_CHARACTERS_CUT}
        />
      </FieldsContainer>
    </Form>
  );
};

export default CheckoutForm;
