import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from './styles';
import CurrentPlan from './CurrentPlan';
import PurchasePlans from './PurchasePlans';
import { useConnect } from './connect';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerTitle,
  MainContainerLoader,
} from '../../../../components/MainContainer';
import CheckoutResultAlerts from './CheckoutResultAlerts';
import { NotificationMessages } from '../../../../components/NotificationMessages';
import CompleteFeatureList from "./CompleteFeatureList";

const Billing = () => {
  const {
    workspaceDetails,
    messagesHandler,
    purchasePlansConnect,
    currentPlanConnect,
    isFree,
    wrongPlanRequested,
    queryParams,
    accountType,
  } = useConnect();

  if (wrongPlanRequested) {
    return (
      <div>
        <Redirect to={`/wrong-plan?${queryParams}`} />
      </div>
    );
  }

  if (
    purchasePlansConnect.isLoadingSubscription ||
    purchasePlansConnect.isLoadingPrices ||
    currentPlanConnect.isLoading
  ) {
    return <MainContainerLoader />;
  }

  return (
    <MainContainer title="Billing">
      <NotificationMessages handler={messagesHandler} />
      <MainContainerHeader direction="horizontal"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      >
        <MainContainerTitle append={workspaceDetails?.name}>
          Billing
        </MainContainerTitle>
          {isFree && <CompleteFeatureList isLegacyPlanType={currentPlanConnect.isLegacy}/>}
      </MainContainerHeader>
      <Container>
        <CheckoutResultAlerts />
        <CurrentPlan {...currentPlanConnect} accountType={accountType} />
        {isFree && (
          <>
            <span />
            <PurchasePlans {...purchasePlansConnect} />
          </>
        )}
      </Container>
    </MainContainer>
  );
};

export default Billing;
