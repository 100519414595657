import styled from 'styled-components';
import { Text, Button, Icon } from '@scriptaddicts/yamm-ui-components';

export const StyledTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.blue500};
  display: block;
  margin-bottom: 10px;
`;

export const StyledPrice = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 100%;
  letter-spacing: -0.015em;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const StyledAnnualPrice = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 165%;
  letter-spacing: -0.21px;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const StyledSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  margin: 26px 0;
`;

export const StyledStrike = styled.s`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledFeatureTitle = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.blue500};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
`;

export const StyledFeatureText = styled(Text).attrs({
  type: 'paragraph',
})`
  line-height: 18px !important;
`;

export const StyledUpgradeButton = styled(Button)`
  margin-top: 28px;
  margin-bottom: 30px;
`;

export const BilledAtLabel = styled(Text).attrs({
  type: 'paragraph',
})`
  color: ${({ theme }) => theme.colors.gray700};
  margin-top: 4px;
  b {
    font-weight: bold;
  }
`;

export const StyledInfo = styled(Icon).attrs({
  name: 'info',
})`
  margin-left: 6px;
  cursor: pointer;
`;

export const StyledUpToText = styled.span`
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
`;

export const PurchasePlanCardContainer = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.gray300};
    background: ${({theme}) => theme.colors.white};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const PurchasePlanCardHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const PurchasePlanCardHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
`;

export const PurchasePlanCardHeaderText = styled(Text)`
    align-self: stretch;
    color: ${({theme}) => theme.colors.blue500};
    font-family: ${({theme}) => theme.fonts.lexendDeca};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 32px */
`;

export const PurchasePlanCardHeaderSubText = styled(Text)`
    align-self: stretch;
    color: ${({theme}) => theme.colors.gray700};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%; /* 23.1px */
    letter-spacing: -0.21px;
`;

export const PurchasePlanCardPriceContainer = styled.div`
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export const PurchasePlanCardFeatureTitle = styled(Text)`

    color: ${({theme}) => theme.colors.blue500};
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    letter-spacing: -0.24px;

`;

export const PurchasePlanCardFeaturesContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

`;

export const PurchasePlanCardMonthlyPrice = styled(Text)`
    color: ${({theme}) => theme.colors.gray800};
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.27px;
`;

export const PurchasePlanCardMonthlyPriceBigger = styled(PurchasePlanCardMonthlyPrice)`
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.63px;
`;

export const PurchasePlanCardAnnuallyPrice = styled(Text)`
    align-self: stretch;
    color: ${({theme}) => theme.colors.gray700};
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    letter-spacing: -0.21px;
`;

export const PurchasePlanCardAnnuallyPriceBigger = styled(PurchasePlanCardAnnuallyPrice)`
    font-weight: 600;
`;

export const PurchasePlanCardUpgradeButtonContainer = styled(Button)`
    display: flex;
    width: 158px;
    height: 46px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.blue500};
    background: ${({theme}) => theme.colors.blue500};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const PurchasePlanCardUpgradeButtonText = styled(Text)`
    color: ${({theme}) => theme.colors.white};
    font-family: ${({theme}) => theme.fonts.inter};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
    letter-spacing: -0.24px;
`;


