import styled from 'styled-components';


export const CompleteFeatureButton = styled.div`
    position: relative;
    letter-spacing: -0.01em;
    line-height: 125%;
    font-weight: 600;
`;
export const IconWrapper = styled.div`
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
`;

export const CompleteFeatureListContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const CompleteFeatureListWrapper = styled.div`
    min-width: max-content;
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #d7d5d1;
    box-sizing: border-box;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    text-align: left;
    font-size: 14px;
    color: #474340;
    font-family: Inter;
`;


