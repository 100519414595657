import React from 'react';
import { Formik } from 'formik';
import { Alert } from '@scriptaddicts/yamm-ui-components';
import { useConnect } from './connect';
import CheckoutForm from './CheckoutForm';
import CheckoutLayout from '../Layout';
import BillingInformationSidebar from '../BillingInformationSidebar';
import { StyledCard, StyledTitle, StyledTitleWrapper } from '../common/styles';
import { MainContainerLoader } from '../../../components/MainContainer';

const BillingInformation = () => {
  const {
    workspaceDetails,
    isLoading,
    checkoutForm,
    isEuropeanCountry,
    isBusinessPurchase,
    isSubmitting,
    submitError,
    isFetchingPaymentInfo,
    setIsBusinessPurchase,
    setCountry,
    validationSchema,
    onSubmit,
  } = useConnect();

  return (
    <Formik
      initialValues={checkoutForm}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <CheckoutLayout.Container>
        <CheckoutLayout.Main>
          {isLoading || !workspaceDetails ? (
            <MainContainerLoader />
          ) : (
            <>
              {submitError && (
                <Alert type="error" style={{ marginBottom: '20px' }}>
                  An error occurred. Please try again.
                </Alert>
              )}
              <StyledTitleWrapper>
                <StyledTitle>Upgrading {workspaceDetails.name}</StyledTitle>
              </StyledTitleWrapper>
              <StyledCard variant="white" flex="vertical" padding="big">
                <CheckoutForm
                  defaultCountry={checkoutForm.country}
                  isEuropeanCountry={isEuropeanCountry}
                  isBusinessPurchase={isBusinessPurchase}
                  handleCountryChange={setCountry}
                  handleTypeOfBusinessChange={setIsBusinessPurchase}
                  defaultVATNumber={checkoutForm.vatNumber}
                  defaultCompanyName={checkoutForm.companyName}
                  disabled={isSubmitting}
                />
              </StyledCard>
            </>
          )}
        </CheckoutLayout.Main>
        <CheckoutLayout.Sidebar>
          <BillingInformationSidebar
            disabled={isFetchingPaymentInfo || isLoading}
            isSubmitting={isSubmitting}
          />
        </CheckoutLayout.Sidebar>
      </CheckoutLayout.Container>
    </Formik>
  );
};

export default BillingInformation;
