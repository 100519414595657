import {queryClient} from 'data/queryClient';
import {UserInput} from 'model/user';
import {isGmailAddress} from 'utils/userUtils';

interface ClaritySegment {
    version: string | undefined;
    ui_type: string;
    is_google_workspace: string | undefined;
    is_paid: string | undefined;
    is_legacy_paid: string | undefined;
}

export const ClarityUtils = {
    setup: () => {
        try {
            ((
                window: Window & typeof globalThis,
                document: Document,
                trackingCode: string,
            ) => {
                const w = window as any;
                w.clarity =
                    w.clarity ||
                    ((...args: any[]) => {
                        (w.clarity.q = w.clarity.q || []).push(args);
                    });
                const t: HTMLScriptElement = document.createElement('script');
                t.async = true;
                t.src = `https://www.clarity.ms/tag/${trackingCode}`;
                const y: HTMLScriptElement = document.getElementsByTagName('script')[0];
                y.parentNode?.insertBefore(t, y);
            })(window, document, `${process.env.REACT_APP_CLARITY_TRACKING_CODE}`);
        } catch (error) {
            /* eslint-disable-next-line no-console */
            console.error('Error setting up Clarity', error);
        }
    },
    isSetup: () => {
        return typeof (window as any).clarity === 'function';
    },
    identify: (customId: string | null, customSessionId: string | undefined) => {
        try {
            if (typeof (window as any).clarity === 'function') {
                (window as any).clarity('identify', customId, customSessionId);
            }
        } catch {
            // continue regardless of error
        }
    },
    event: (eventName: string) => {
        try {
            if (typeof (window as any).clarity === 'function') {
                (window as any).clarity('event', eventName);
            }
        } catch {
            // continue regardless of error
        }
    },
    tag: (key: string, value: string | undefined) => {
        if (value === undefined) {
            return;
        }
        try {
            (window as any).clarity('set', key, value);
        } catch (error) {
            // continue regardless of error
        }
    },
    segment: () => {
        const rawUser = queryClient.getQueryData(['me']) as UserInput | undefined;
        if (!rawUser) return;

        const isPaid = rawUser?.userWorkspaces
            ?.some((workspace) => workspace.userPlan !== 'FREE')
            .toString();

        const isLegacyPaid = rawUser?.userWorkspaces
            ?.some(
                (workspace) =>
                    workspace.workspacePlan === 'LEGACY' && workspace.userPlan !== 'FREE',
            )
            .toString();

        const segment: ClaritySegment = {
            version: process.env.REACT_APP_VERSION,
            ui_type: 'spaces',
            is_google_workspace: isGmailAddress(rawUser.userEmail) ? 'false' : 'true',
            is_paid: isPaid,
            is_legacy_paid: isLegacyPaid,
        };

        Object.entries(segment).forEach(([key, value]) => {
            ClarityUtils.tag(key, value);
        });
    },
    cookieConsent: (granted: boolean = true) => {
        try {
            if (typeof (window as any).clarity === 'function') {
                (window as any).clarity('consent', granted);
            }
        } catch (error) {
            /* eslint-disable-next-line no-console */
            console.error('Error granting clarity cookies', error);
        }
    },
};
